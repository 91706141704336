@import './_vars';

$theme-colors: (
  'base': $light,
  'base-font': $black,
  'secondary-font': $gray-400,
  'primary-font': $dark,
  'base-secondary': $light,
  'base-tertiary': $light,
  'highlight': $highlight,
);

.light-mode {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  p,
  .p,
  u,
  .btn-text,
  .btn-base,
  small,
  .small,
  .form-label,
  ul,
  b,
  option,
  label {
    color: $dark;
  }

  .card {
    background-color: $light;
  }

  .primary-hover:hover {
    background-color: $gray-200 !important;
  }

  .btn-text {
    &:hover {
      color: $primary !important;
      background-color: $light;
    }
    &:focus,
    &:active {
      color: $primary;
      background-color: $light;
    }
  }

  .btn-base {
    &:hover {
      color: $dark;
    }
    &:focus,
    &:active {
      color: $dark;
    }
  }

  .form-select,
  .form-control,
  .text-input,
  input,
  select {
    border: 0px;
    background-color: $gray-100;
    color: $gray-400;
    &::placeholder {
      color: $gray-400;
      opacity: 1; /* Firefox */
    }
    &:focus,
    &:active {
      background-color: $gray-100;
      color: $gray-400;
    }
    &::-ms-input-placeholder {
      color: $gray-400;
    }
  }

  .form-check-input {
    &:checked {
      background-color: $primary;
    }
  }

  .accordion,
  .accordion-item,
  .accordion-button {
    background-color: $light;
    color: $gray-900;
    &:focus,
    &:active,
    &:visited {
      background-color: $light;
      color: $gray-900;
    }
  }

  .dropdown-menu {
    border: 1px solid $gray-400;
    background-color: $light;
    z-index: 5;
    .dropdown-item {
      color: $dark;
      background-color: $light;
      &:hover {
        color: $light;
        background-color: $primary;
      }
    }
  }

  @each $key, $color in $theme-colors {
    .btn-#{$key} {
      background-color: $color !important;
      border: 0px;
      box-shadow: none !important;
      &:hover,
      &:focus,
      &:active {
        background-color: $color !important;
        filter: brightness(90%);
        border-color: $color;
        box-shadow: none !important;
      }
    }

    .badge-#{$key} {
      background-color: $color !important;
      border: 0px;
    }

    .text-#{$key} {
      color: $color !important;
    }

    .bg-#{$key} {
      background-color: $color !important;
    }

    .border-#{$key} {
      border-color: $color !important;
    }
  }
}
